import React from 'react'
import { useSlotManager } from '../SlotContext'

export default function TimeSlot({ item, onClick, index }) {

    const {
        inGuestMode,
        guestSlots
    } = useSlotManager();

    const checkStyling = guestSlots[index] && guestSlots[index].status ? ' checked' : '';

    return (
        <div className={
            'timeslot ' +
            (inGuestMode() ? 'guest-mode' : '') +
            (item.status ? ' unavailable' : '') +
            checkStyling
        }>
            {/* TODO use date functions to properly format the hours */}
            <span>{(item.time < 10 ? '0' : '') + item.time}:00</span>

            <div onClick={onClick} className={'status'}>
                {
                    !checkStyling ? (!item.status ? 'free' : 'unavailable') : 'reserved'
                }
            </div>
        </div>
    )
}
