import React, { useState } from 'react'
import { useSlotManager } from '../SlotContext';
import Box from './Box';
import BoxSection from './BoxSection';
import TimeSlot from './TimeSlot';
import { FaArrowCircleRight } from "react-icons/fa";
import Button from '../components/Button';
import Alert from './common/Alert';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';

export default function SlotMatcher() {

    const {
        slots,
        uniqueIdentifier,
        parseSlots,
        isBooked,
        setGuestMode,
        guestSlots,
        toggleSlot,
        inGuestMode,
        eventName,
        finalEncode,
        eventDescription
    } = useSlotManager();

    const toggle = (index) => {
        toggleSlot(index);
    }

    const [done, setDone] = useState(false);

    /**
     * 
     */
    const finalize = () => {
        // Create the final encode code
        const UID = finalEncode();
        setDone(UID);
        navigator.clipboard.writeText(UID);
    }

    return (
        <Box title={'Event: ' + eventName}>
            {eventDescription && <p>{eventDescription}</p>}

            {/* TODO allow creater to decide how much time is needed. For example maybe 2 hours are required */}
            <BoxSection title='How to use'>
                <p>
                    You've been invited to an event. The suggested timeslots are displayed underneath. Click on the timeslots that work for you.
                </p>
            </BoxSection>

            {/*<div className='input-row'>
                <input id='show-free-only' type="checkbox" onChange={() => toggleFree()} />
                <label htmlFor="show-free-only">Only show free slots</label>
             </div>
            */}

            <BoxSection>
                <div className='timeslots'>
                    {slots.map((item, index) => {
                        // Render if filtering is off OR the the active filter does not apply to the current item
                        //if ((showFreeOnly && !isBooked(item) || !showFreeOnly)) {
                        return <TimeSlot key={index} index={index} item={item} onClick={!item.status ? () => toggle(index) : undefined} />;
                    })}
                </div>
            </BoxSection>
            <BoxSection title='Finish'>
                <p>
                    Once you're done, click on the button "finalize" and share the link with whoever invited you.
                </p>
            </BoxSection>

            <div className='input-row flexRight'>
                {/* TODO tooptip on success */}
                <Button onClick={() => finalize()}>Finalize<FaArrowCircleRight className='icon' /></Button>
                {done && <WhatsappShareButton
                    url={'https://swipeon.it/results' + '?uid=' + done}
                    title={"I responded"}
                    separator=":: "
                >
                    {/*round*/}
                    <WhatsappIcon size={48} />
                </WhatsappShareButton>}
            </div>

        </Box>

    )
}
