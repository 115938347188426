import React, { useEffect, useState } from 'react'
import Box from '../components/Box'
import BoxSection from '../components/BoxSection'
import { FaRegCopy } from "react-icons/fa";
import Button from '../components/Button';
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { useSlotManager } from '../SlotContext';



export default function Create() {

    const {
        slots,
        toggleSlot,
        setSlots,
        uniqueIdentifier,
        eventName,
        setEventName,
        eventDescription,
        setEventDescription,
        reset,
        encode,
        setUniqueIdentifier
    } = useSlotManager();

    const [showFreeOnly, setShowFreeOnly] = useState(false);

    // TODO length of activity

    /* TODO rewrite format. name : [
        [start, end],
        [start, end]
    ]*/
    const templates = {
        '08:00-12:00': [
            { 'time': 8, 'status': 0 },
            { 'time': 9, 'status': 0 },
            { 'time': 10, 'status': 0 },
            { 'time': 11, 'status': 0 },
            { 'time': 12, 'status': 0 },
            { 'time': 13, 'status': 0 },
            { 'time': 14, 'status': 0 },
            { 'time': 15, 'status': 0 },
            { 'time': 16, 'status': 0 }
        ],
        '13:00-16:00': [
            { 'time': 13, 'status': 0 },
            { 'time': 14, 'status': 0 },
            { 'time': 15, 'status': 0 },
            { 'time': 16, 'status': 0 }
        ],
        '16:00-22:00': [
            { 'time': 16, 'status': 0 },
            { 'time': 17, 'status': 0 },
            { 'time': 18, 'status': 0 },
            { 'time': 19, 'status': 0 },
            { 'time': 20, 'status': 0 },
            { 'time': 21, 'status': 0 },
            { 'time': 22, 'status': 0 },
        ]
    }

    const setTemplate = (name) => {
        // Search for object key
        if (name in templates) {
            // Set new slots
            setSlots([...templates[name]]);
        }
    }

    const toggle = (index) => toggleSlot(index);

    useEffect(() => {
        setUniqueIdentifier(encode())
    }, [slots, eventName, eventDescription])

    /**
     * TODO MOVE TO HOOK
     */
    const copyToClipboard = () => {
        navigator.clipboard.writeText(uniqueIdentifier);
    }

    return (
        <div className='main-wrapper'>
            <Box title={'Create a timeslot finder'}>

                <label htmlFor="slot-list-title">Title of slot list (optional)</label>
                <div className='input-row'>
                    <input id="slot-list-title" type='text' value={eventName} onChange={(e) => setEventName(e.target.value)} placeholder='Title' />
                </div>

                <label htmlFor="slot-list-description">Description of event (optional)</label>
                <div className='input-row'>
                    <input id="slot-list-description" type='text' value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} placeholder='Description' />
                </div>

                <label htmlFor="slot-list-title">Common templates</label>
                <div className='input-row'>
                    {Object.entries(templates).map(([key, value]) => {
                        return <Button key={key} onClick={() => setTemplate(key)}>{key}</Button>
                    })}

                </div>

                <div className='timeslots'>
                    {slots.map((item, index) => {

                        // Skip rendering slot if it's booked slot and only free slots aught to be shown
                        if (showFreeOnly && item.status) {
                            return;
                        }
                        return <div key={item.time} className='timeslot'>
                            <div onClick={() => toggle(index)} className={!item.status ? 'status' : 'status booked'}>{(item.time < 10 ? '0' : '') + item.time}:00</div>
                        </div>
                    })}
                </div>

                <strong>Share link</strong>
                <p>
                    Copy the link below and give it to the person you want to
                    find a timeslot with.
                </p>

                <div className='input-row'>
                    <input readOnly type='text' onClick={(e) => e.target.select()} value={uniqueIdentifier} />
                    {/* TODO tooptip on success */}
                    <Button onClick={() => copyToClipboard()}><FaRegCopy className='icon' />Copy to clipboard</Button>
                </div>

                <strong>or</strong>
                <p>
                    Share it directly with any of the following apps
                </p>

                <WhatsappShareButton
                    url={'https://swipeon.it/edit' + '?uid=' + uniqueIdentifier}
                    title={"Let's get together. Here's my schedule"}
                    separator=": "
                >
                    {/*round*/}
                    <WhatsappIcon size={48} />
                </WhatsappShareButton>

            </Box>
        </div>
    )
}
