import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import './assets/App.css';
import Router from './Router';
import { SlotProvider } from './SlotContext';

ReactDOM.render(
    <React.StrictMode>
        <SlotProvider>
            <Router />
        </SlotProvider>
    </React.StrictMode>,
    document.getElementById('root')
);