import React, { useEffect, useState } from 'react'
import Box from '../components/Box'
import BoxSection from '../components/BoxSection'
import { FaArrowCircleRight } from "react-icons/fa";
import Button from '../components/Button';
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { useSlotManager } from '../SlotContext';
import TimeSlot from '../components/TimeSlot';
import Calendar from '../components/Calendar';
import SlotMatcher from '../components/SlotMatcher';
import EnterUid from '../components/EnterUid';
import EventDisplay from '../components/EventDisplay';

const BASE_URL = 'http://localhost:3000/';

/*
    TODO
    allow to add multiple sets of slots. This way you can invite multiple friends and see where you're all free...
*/

export default function Results() {

    const [uid, setUid] = useState();

    const {
        uniqueIdentifier,
        setUniqueIdentifier,
        parseSlots,
        setGuestMode,
        error,
        setError
    } = useSlotManager();


    const [loadedInvite, setLoadedInvite] = useState(false);

    /* Listen to changes of the unique identifier and try parsing it */
    useEffect(() => {

        try {
            parseSlots(uniqueIdentifier);
            // In guest mode, changes are done to a different list. This way the original list is retained

            setLoadedInvite(true);
            setError(null);
        } catch {
            setError('Invalid UID provided. Did you copy the whole string?');
        }

    }, [uniqueIdentifier])


    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        // If we have an invite argument in the query search string
        if (params.has('uid')) {
            setUniqueIdentifier(params.get('uid'));
        }
    }, []);



    return (
        <div className='main-wrapper'>
            {
                (loadedInvite && <EventDisplay />)
                ||
                <EnterUid error={error} />
            }
        </div>
    )
}
