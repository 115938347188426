import React, { useEffect, useState } from 'react'
import Box from '../components/Box'
import BoxSection from '../components/BoxSection'
import { FaPlusSquare, FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";


export default function Dashboard() {


    return (
        <>
            <Box title={'Welcome'}>

                <BoxSection>
                    <p>
                        What do you want to do? If you have a identifier for a session that was given to you, click on "edit".
                    </p>
                </BoxSection>

                <div className='input-row'>
                    <Link to="/create" className='button full-flex'><FaPlusSquare className='icon' />Create</Link>
                    <Link to="/edit" className='button full-flex'><FaRegEdit className='icon' />Respond</Link>
                    <Link to="/results" className='button full-flex'><FaRegEdit className='icon' />See results</Link>
                </div>
            </Box>
        </>
    )
}
