import React, { useState } from 'react'
import { useSlotManager } from '../SlotContext';
import Box from './Box';
import BoxSection from './BoxSection';
import TimeSlot from './TimeSlot';
import { FaArrowCircleRight, FaPlusSquare } from "react-icons/fa";
import Button from '../components/Button';
import Alert from './common/Alert';
import { Link } from 'react-router-dom';

export default function EventDisplay() {

    const {
        slots,
        uniqueIdentifier,
        parseSlots,
        isBooked,
        setGuestMode,
        guestSlots,
        toggleSlot,
        inGuestMode,
        eventName,
        finalEncode,
        eventDescription
    } = useSlotManager();



    return (
        <Box title={'Event: ' + eventName}>
            {eventDescription && <p>{eventDescription}</p>}

            {/*<div className='input-row'>
                <input id='show-free-only' type="checkbox" onChange={() => toggleFree()} />
                <label htmlFor="show-free-only">Only show free slots</label>
             </div>
            */}

            <BoxSection>
                <div className='timeslots view-mode'>
                    {slots.map((item, index) => {
                        return <TimeSlot key={index} index={index} item={item} />;
                    })}
                </div>
            </BoxSection>

        </Box>
    )
}
