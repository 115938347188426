import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import React from 'react'
import Create from "./screens/Create";
import Edit from "./screens/Edit";
import Dashboard from "./screens/Dashboard";
import Results from "./screens/Results";

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="create" element={<Create />} />
                <Route path="edit" element={<Edit />} />
                <Route path="results" element={<Results />} />
            </Routes>
        </BrowserRouter>
    )
}
