import React, { useState, useEffect, createContext, useContext, useRef } from 'react';

const Buffer = require('buffer/').Buffer;

const SlotContext = createContext();

export function useSlotManager() {
    return useContext(SlotContext);
}

export const SlotProvider = (props) => {

    // The encoded and compressed JSON object
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');

    // Current working slot
    const [slots, setSlots] = useState([]);
    const [guestSlots, setGuestSlots] = useState([]);
    const [slotsLoaded, setSlotsLoaded] = useState(false);
    const guestMode = useRef(false);
    const [eventName, setEventName] = useState('');
    const [error, setError] = useState('');
    const [eventDescription, setEventDescription] = useState('');



    /* Props and functions accessible from the hook */
    const value = {
        eventName,
        setEventName,
        slots,
        setSlots,
        encode,
        decode,
        uniqueIdentifier,
        setUniqueIdentifier,
        parseSlots,
        isBooked,
        guestSlots,
        toggleSlot,
        setGuestMode,
        inGuestMode,
        error,
        setError,
        finalEncode,
        eventDescription,
        setEventDescription,
        reset,
        encode
    };


    // We make sure to keep the base64 string updated to reflect the changes of the slots state
    useEffect(() => {
        setSlotsLoaded(true);

        if (inGuestMode() && slotsLoaded) {
            // Duplicate all the timeslots of the author, but change the status to free
            const duplicated = slots.map((item) => { return { ...item, status: false } });
            setGuestSlots([...duplicated]);
        }

    }, [slots, eventName]);


    function reset() {
        setEventDescription('');
        setEventName('');
        setSlots([]);
        setGuestMode(false);
        setSlotsLoaded(false);
        setGuestSlots([]);
    }


    /**
     * Final encode for when the guest has finalized their answer
     */
    function finalEncode() {
        let obj = baseObj();

        /*const responded = [];
        guestSlots.forEach((item) => {
            if (item.status) {
                responded.push(item.time);
            }
        });
        obj = { ...obj, 'guest': responded };
*/
        obj = { ...obj, 'guest': guestSlots };

        console.log(obj);

        const jsonString = JSON.stringify(obj);
        return Buffer.from(jsonString).toString('base64');
    }


    function baseObj() {
        return {
            'name': eventName,
            'description': eventDescription,
            'availability': slots
        }
    }

    function encode() {

        if (!eventName && slots.length === 0) {
            return '';
        }

        let obj = baseObj();

        if (inGuestMode()) {
            obj = { ...obj, 'guest': guestSlots };
        }


        const jsonString = JSON.stringify(obj);
        return Buffer.from(jsonString).toString('base64');
        //  TODO compression
        // zlib?
    }

    function decode(base64Str) {
        // TODO decompress
        const jsonString = Buffer.from(base64Str, 'base64').toString('utf8');
        return JSON.parse(jsonString);
    }

    /**
     * 
     * @param {*} encodedStr 
     * @throws 
     */
    function parseSlots(encodedStr) {
        const parsed = decode(encodedStr);

        if (!parsed.availability || parsed.availability.length === 0) {
            throw 'Empty';
        }

        setEventName(parsed.name);
        setEventDescription(parsed.description);
        setSlots(parsed.availability);
        if (parsed.guest) {
            setGuestSlots(parsed.guest);
        }
    }

    function isBooked(item) {
        return item.status;
    }


    function toggleSlot(index) {

        // No direct editing is allowed in guest mode
        if (!inGuestMode()) {
            slots[index].status = !slots[index].status
            setSlots([...slots]);
        } else {
            guestSlots[index].status = !guestSlots[index].status;
            setGuestSlots([...guestSlots]);
        }

    }


    function inGuestMode() {
        return guestMode.current;
    }

    // In guest mode, the guest can't edit the already defined slots
    function setGuestMode() {
        guestMode.current = true;
    }

    return (
        <SlotContext.Provider value={value}>
            {props.children}
        </SlotContext.Provider>
    )

}