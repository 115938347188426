import React, { useEffect, useState } from 'react'
import Box from './Box'
import Button from './Button'
import Alert from './common/Alert'
import { FaPaste } from "react-icons/fa";
import BoxSection from './BoxSection';
import { useSlotManager } from '../SlotContext';

export default function EnterUid(props) {

    const {
        uniqueIdentifier,
        setUniqueIdentifier
    } = useSlotManager();

    const pasteFromClipboard = () => {
        navigator.clipboard.readText().then(
            text => setUniqueIdentifier(text)
        );
    }

    return (
        <Box title={'Find event'}>
            {props.error && <Alert type='error'>{props.error}</Alert>}

            <BoxSection title='Enter UID'>

                <div className='input-row'>
                    <input type='text' value={uniqueIdentifier} onChange={(e) => setUniqueIdentifier(e.target.value)} placeholder='Paste UID here' />

                    <Button onClick={() => pasteFromClipboard()}><FaPaste className='icon' />Paste from clipboard</Button>
                </div>
            </BoxSection>
        </Box>
    )
}
